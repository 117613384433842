<template>
  <div class="contanter_pay">
    <headerTab></headerTab>
    <div class="time_box" v-if="tableData.pay_time!=null">
      支付剩余时间:{{dataTimeText}}
    </div>
    <div class="p_text1">说明：居间服务费用是收取的居间报酬，不会产出额外费用，该笔费用由转出人承担，受让人代付。请在规定时间内完成缴费，否则视为放弃受让！</div>
    <div class="pay_time">下单时间：{{tableData.create_time}}</div>
    <div class="user_info_pay">
      <div style="display: flex">
        <div>转让工分份:</div>
        <div style="color:#FF3E46">{{tableData.sales.shares}}</div>
      </div>

    </div>

    <div class="user_info_pay">
      <div style="display: flex">
        <div>居间税率:</div>
        <div style="color:#FF3E46">{{tableData.sales.sales_tax_rate_text}}</div>
      </div>
      <div style="display: flex">
        <div>价值:</div>
        <div style="color:#FF3E46">{{tableData.sales.shares_price}}元/股</div>
      </div>
    </div>
    <div class="price_text" style="margin-bottom: 10px">应付转让费：<span>￥{{queryList.type?tableData.sales.shares_sales:tableData.sales.tax_amount}}</span></div>
    <div class="price_text" style="color:#FF3E46;margin:0" v-if="!queryList.type">(服务费)</div>

    <div class="shpace" v-if="!queryList.type">
      <div class="title_pay">收款账户一</div>
      <div>账号主体:{{configData.shares_sales_payee}}</div>
      <div>对公账户:{{configData.shares_sales_payee_account}}</div>
      <div>开户行详情:{{configData.shares_sales_payee_address}}</div>
    </div>

    <div class="shpace" v-else>
      <div class="title_pay">收款账户</div>
      <div>账号主体:{{tableData.sales_user.union_info.bank.userName}}</div>
      <div>对公账户:{{tableData.sales_user.union_info.bank.number}}</div>
      <div>开户行详情:{{tableData.sales_user.union_info.bank.bank}}</div>
    </div>
    <div style="display:flex">
      <div class="title_pay">上传打款凭证： </div>
      <div style="color:red;font-size:12px;margin-top:10px;margin-left:10px">*打款前请认真核对打款账户，最多上传2张凭证</div>
    </div>

    <div class="formList">
     <!-- <van-uploader v-on:oversize="oversize" class="imgFlexd" v-if="fileList.length<3" multiple :before-read="beforeRead" :max-count="1" />
      <div v-if="fileList.length>0" style="display:flex">
        <div class="imgClass" v-for="(item,index) in fileList" :key="index">
          <img :src="item.url" />
          <div class="box_clone" @click="imgBtnClone(1,index)">
            <img class="imgs" src="../../assets/cloneIcon.png" />
          </div>
        </div>
      </div> -->
      <uploadImg :isNumber="isNumber" :uploadData="uploadData" @uploadImg="uploadImg"></uploadImg>
    </div>
    <div style="margin-top:30px">
      <div class="formList">备注：</div>
      <textarea v-model="address" class="textarea"></textarea>
    </div>
    <div style="display:flex;margin-top:58px">
      <div v-if="iconShow==false" @click="checkoutBtn(1)" class="select_box"></div>
      <div v-else class="select_box" @click="checkoutBtn(2)" style="background:#87CEFA"></div>
      <div class="ruleBtn" @click="ruleBtn">我已熟知与查看《居间协议》</div>
    </div>
    <div class="office_submit" @click="submit">
      <img src="../../assets/submitBtn.png" />
    </div>
    <!-- 签字弹窗 -->
    <div class="signtureShow1" v-if="signtureShow" @click="signtureShow">
      <div class="signtureShow_box">
        <catSignature :imgWidth="imgWidth" @signatureBtn="signatureBtn" @cloneBtn="cloneBtn1" :imgToken="imgToken" @cloneImg="cloneImg" @imgUblod="imgUblod"></catSignature>
      </div>
    </div>

  </div>
</template>
<script>
import * as apiCommon from "@/api/common";
import { Toast } from "vant";
import { dealpay_api, getConfig_api, transfer_api } from "@/api/deal";
// import wx from "weixin-js-sdk";
import catSignature from "../../components/catSignature.vue";
import headerTab from "../../components/headerTab";
import uploadImg from "../../components/uploadImg";
export default {
  data() {
    return {
      checked: [],
      fileList: [],
      iconShow: false,
      queryList: {},
      imgWidth: 645,
      tableData: {
        salesUser: {
          union_info: {
            bank: {}
          }
        },
        sales: {}
      },
      imgToken: "",
      time: "",
      dataTimeText: "",
      timeData: null,
      address: "",
      configData: {
        shares_sales_payee: "",
        shares_sales_payee_account: "",
        shares_sales_payee_address: ""
      },
      form: {
        nameImg: ""
      },
      toastShow: false,
      signtureShow: false,
      payShow: true,
      uploadData: {
        getToken: "",
        imgConfig: "{ useCdnDomain: true }",
        num:10
       },
      isNumber:true,
    };
  },
  created() {
    document.title = "支付";
    this.queryList = this.$route.query;
    this.getDetails(this.queryList.id);
    this.getToken();
    // 获取配置
    this.getConfig();
  },
  components: {
    catSignature,
    headerTab,
    uploadImg
  },
  destroyed() {
    console.log(1);
    clearInterval(this.timeData);
  },
  watch: {
    time(newValue) {
      this.dataTimeText =
        Math.trunc(newValue / 3600) +
        "小时" +
        Math.trunc((newValue % 3600) / 60) +
        "分" +
        ((newValue % 3600) % 60) +
        "秒";
    }
  },
  methods: {
    uploadImg(img){
      this.fileList = img
    },
    signatureBtn() {
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout(e => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    getConfig() {
      let data = {
        config: [
          "shares_sales_payee",
          "shares_sales_payee_account",
          "shares_sales_payee_address"
        ]
      };
      getConfig_api(data).then(res => {
        console.log(res.data);
        this.configData = res.data;
        this.$forceUpdate();
      });
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      this.uploadData.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../lador/service");
    },
    ruleBtn() {
      this.$router.push(`./contract?id=${this.queryList.id}`);
    },
    oversize() {},
    submit() {
      if (!this.address) {
        Toast({
          message: "地址信息不能为空",
          icon: "error",
          duration: 1000
        });
        return false;
      }
      if (this.fileList.length == 0) {
        Toast({
          message: "打款凭证不能为空",
          icon: "error",
          duration: 1000
        });
        return false;
      }
      if (this.iconShow == false) {
        Toast({
          message: "请完成协议",
          icon: "error",
          duration: 1000
        });
        return false;
      }
      let data = {
        tax_pay_info: {
          voucher: this.fileList,
          address: this.address
        }
      };
      let data1 = {
        remit_info: {
          voucher: this.fileList,
          address: this.address,
          bank: this.tableData.sales_user.union_info.bank.bank,
          number: this.tableData.sales_user.union_info.bank.number,
          userName: this.tableData.sales_user.union_info.bank.userName
        }
      };
      if (!this.payShow) {
        return false;
      }
      this.payShow = false;
      if (this.queryList.type) {
        transfer_api(data1, this.tableData.trade_id).then(res => {
          this.payShow = true;
          if (res.code == 0) {
            Toast({
              message: "提交成功",
              icon: "error",
              duration: 1000
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          }
        });
      } else {
        dealpay_api(data, this.tableData.trade_id).then(res => {
          this.payShow = true;
          if (res.code == 0) {
            Toast({
              message: "提交成功",
              icon: "error",
              duration: 1000
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          }
        });
      }
    },
    imgUblod(img) {
      console.log(img, "1111111111");
      this.signtureShow = false;
      this.iconShow = true;
      this.form.nameImg = img;
      this.toastShow = true;
      this.configData.title = "签名完成";
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
      this.popShow = true;
      localStorage.setItem("imgName", img);
    },
    cloneImg() {
      (this.dataForm.nameImg.value = ""), localStorage.removeItem("imgName");
    },
    cloneBtn1() {
      console.log(1);
      this.signtureShow = false;
      this.$forceUpdate();
    },
    async getDetails(id) {
      let res = await apiCommon.putOrder_api(id);
      if (res.code == 0) {
        console.log(res.data);
        this.tableData = res.data;
        this.time = this.tableData.pay_time;
        this.timeData = setInterval(() => {
          this.time--;
        }, 1000);
        this.timeData;
        this.iconShow = true;
        console.log(this.tableData, "?3123");
        this.$forceUpdate();
        // if(this.tableData.shipments.length>0){
        //   this.logistics()
        // }
      }
    },
    // 上传凭证
    beforeRead(e) {
      console.log(e, "file");
      let img = e;
      this.upload(img);
    },
    imgBtnClone(index, id) {
      console.log(index, "11");
      this.fileList.splice(id, 1);
    },
    checkoutBtn(index) {
      if (index == 1) {
        this.iconShow = true;
        // this.signtureShow = true;
      } else {
        this.iconShow = false;
      }
      this.$forceUpdate();
    },
    async upload(img) {
      let fd = new FormData();
      fd.append("token", this.imgToken);
      fd.append("config", this.imgConfig);
      fd.append("file", img);
      let imgRes = await apiCommon.uploadImg(fd);
      this.imgUrl = "https://cdn.health.healthplatform.xyz/" + imgRes.key;
      this.fileList.push({ url: this.imgUrl });
      console.log(this.fileList, this.imgUrl, "23213");
    }
  }
};
</script>

<style>
.signtureShow1 {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.signtureShow_box {
  width: 80%;
  background-color: #fff;
  margin: 150px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.imgClass {
  position: relative;
  display: flex;
}
.imgClass img {
  width: 100px;
  height: 100px;
}
.box_clone {
  width: 20px;
  /* height:20px; */
  border-radius: 20px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
.imgFlexd {
}
.box_clone .imgs {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.messageTitle_pay .messageIcon_pay {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
/* .signtureShow {
  position: fixed;
  left: 30px;
  top: 100px;
  width: 645px;
  height: 900px;
  background-color: #fff;
} */
.messageTitle_pay {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 10;
  top: -13px;
  right: 20px;
  color: #000;
}
.contanter_pay {
  width: 100%;
  position: relative;
  padding: 0px 71px 0 51px;
  box-sizing: border-box;
}
.clonePay {
  font-size: 22px;
  font-weight: 800;
}
.time_box {
  width: 450px;
  height: 55px;
  background: #2e2e30;
  opacity: 1;
  border-radius: 34px;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 55px;
  margin: 30px auto 85px;
}
.pay_time {
  font-size: 30px;
  color: #585858;
  border-bottom: 1px solid #ececec;
  padding-bottom: 30px;
}
.user_info_pay {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 30px;
  font-size: 26px;
  text-align: center;
  margin-top: 27px;
}
.price_text {
  margin: 26px 0 57px;
  font-size: 26px;
  color: #2e2e30;
  text-align: right;
}
.price_text span {
  font-size: 30px;
  color: #ff3e46;
}
.pay_message {
  font-size: 24px;
  color: #deb77a;
  margin-block-end: 39px;
}
.shpace {
  font-size: 22px;
  color: #2e2e30;
  margin-bottom: 30px;
  border-bottom: 1px solid #ececec;
}
.checkout {
  font-size: 24px;
}
.shpace {
  margin-top: 100px;
}
.shpace > div {
  margin-bottom: 40px;
  font-size: 25px;
}
.shpace img {
  width: 300px;
}
.contanter_pay .title_pay {
  font-size: 40px;
  font-weight: 800;
  color: #0f87ff;
  width: 500px;
}
.offine_title {
  font-size: 26px;
  font-weight: 800;
  margin-top: 30px;
}
.formList {
  display: flex;
  margin-top: 30px;
  font-size: 40px;
  font-weight: 800;
}
.van-uploader__upload {
  width: 162px;
  height: 162px;
  background: #f1f8ff;
  border: 1px solid #63b0ff;
  opacity: 1;
  border-radius: 5px;
}
textarea {
  width: 477px;
  height: 195px;
  background: #ffffff;
  border: 2px solid #9b9b9b;
  opacity: 0.95;
  border-radius: 5px;
  margin: 41px 0 0 50px;
  font-size: 25px;
}

.ruleBtn {
  color: #deb77a !important;
  margin-left: 20px;
  font-size: 25px;
}
.select_box {
  width: 32px;
  height: 32px;
  border: 1px solid #deb77a;
  opacity: 1;
  border-radius: 0px;
  margin-left: 100px;
}
.office_submit {
  width: 206px;
  height: 68px;
  margin: 10px auto 100px;
}
.office_submit img {
  width: 100%;
  height: 100%;
}
.p_text1 {
  font-size: 24px;
  color: #707070;
  margin: 40px 0;
  letter-spacing: 3px;
  line-height: 30px;
}
.messageTitle3 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.header_contract img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.contranct_text1 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
}
.messageTitle3 {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 20px;
  color: #333;
}
</style>

